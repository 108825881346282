<template>
  <v-dialog
    v-if="filingBook"
    v-model="modalData.dialog"
    max-width="950px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- v-if="showNewButton" -->
      <!-- v-if="showNewButton() && permissionCan('create')" -->
      <v-btn
        v-if="showNewButton"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.filing") }}
      </v-btn>

      <!-- <v-btn color="primary" dark class="mb-2 ml-2" @click="handleBack()">
        Vissza az iktatókönyvekhez
      </v-btn> -->

      <a class="mb-2 ml-4 grey--text" href="#" @click.stop="handleBack()">
        Vissza az iktatókönyvekhez</a
      >
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.filings"
                v-model="formModel.status"
                :items="statuses.filings.filings"
                :label="$t('FORMS.status')"
                item-text="value"
                item-value="key"
              >
                <template slot="selection" slot-scope="slotProps">
                  {{ $t("STATUSES." + slotProps.item.value) }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="navi-text">{{
                    $t("STATUSES." + slotProps.item.value)
                  }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <!-- :disabled="formModel.id" -->
              <v-select
                disabled
                v-model="formModel.filing_book_id"
                label="Iktatókönyv"
                :items="filingBookCollection"
                item-text="name"
                item-value="id"
                :rules="requiredRules"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-if="formModel.user_id"
                disabled
                label="Iktatta"
                :value="
                  getUserByID(formModel.user_id)
                    ? getUserByID(formModel.user_id).name
                    : ''
                "
                :error-messages="messages['partner_name']"
                @keyup="messages['partner_name'] = ''"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <label>Iktatás dátuma </label>
              <b-form-datepicker
                v-model="formModel.filing_date"
                class="mb-2"
                @context="onContext"
                locale="hu"
              ></b-form-datepicker>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <label>Érkeztetés / postára adás dátuma </label>
              <b-form-datepicker
                v-model="formModel.postage_date"
                class="mb-2"
                @context="onContext2"
                locale="hu"
              ></b-form-datepicker>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="formModel.partner_name"
                label="Ügyfél neve"
                :error-messages="messages['partner_name']"
                @keyup="messages['partner_name'] = ''"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="formModel.subject"
                :label="$t('FORM_INPUT_NAMES.subject')"
                :error-messages="messages['subject']"
                @keyup="messages['subject'] = ''"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-select
                v-model="formModel.type"
                label="Dokumentum típusa"
                :items="types"
                item-text="value"
                item-value="id"
                :rules="requiredRules"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-select
                v-model="formModel.medium"
                label="Adathordozó típusa"
                :items="mediums"
                item-text="value"
                item-value="id"
                :rules="requiredRules"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-select
                :disabled="!!formModel.administrator_name"
                v-model="formModel.administrator_id"
                label="Ügyintéző"
                :items="employeeCollection"
                item-text="fullName"
                item-value="user_id"
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                :disabled="formModel.administrator_id"
                v-model="formModel.administrator_name"
                label="Külsős ügyintéző neve"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="formModel.document_url"
                label="Dokumentum elektronikus elérési útja (URL)"
                :error-messages="messages['document_url']"
                @keyup="messages['document_url'] = ''"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-btn
                :disabled="!formModel.document_url"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="handleOpenUrl()"
              >
                Ugrás
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="formModel.comment"
                label="Megjegyzés"
                :error-messages="messages['comment']"
                @keyup="messages['comment'] = ''"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn> -->
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
      <SnackBarInfoComponent :snackbarInfo="snackbarInfo">
      </SnackBarInfoComponent>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./Filings";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
// import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
import SnackBarInfoComponent from "@/view/components/SnackBarInfoComponent";
import moment from "moment";

const TRANSLATED_ATRIBUTES = ["company", "name", "text"];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = ["image", "position"];

export const initialFormData = () => ({
  id: null,
  status: 1,

  type: "incoming",
  medium: "electronic",
  filing_book_id: null,
  partner_name: "",
  subject: "",
  user_id: null,
  postage_date: moment(new Date()).format("YYYY-MM-DD"),
  comment: "",
  document_url: "",
  filing_date: moment(new Date()).format("YYYY-MM-DD"),
  filing_number: "",
  administrator_name: "",
  administrator_id: null,

  translations: [],
  custom_fields: [],
});

export default {
  name: "FilingForm",
  props: ["modalData", "permissions", "statuses", "endPoint", "filingBook"],
  components: { SnackBarInfoComponent },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },

      types: [
        {
          id: "incoming",
          value: "Bejövő",
        },
        {
          id: "outgoing",
          value: "Kimenő",
        },
        {
          id: "internal",
          value: "Belső",
        },
      ],
      mediums: [
        {
          id: "paper",
          value: "Papír alapú",
        },
        {
          id: "electronic",
          value: "Elektronikus",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "userCollection",
      "currentUser",
      "filingBookCollection",
      "employeeCollection",

      "getUserByID",
    ]),

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") + this.formModel.filing_number
        : this.$t("MENU.NEW") + " " + this.$t("MENU.FILING");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
    showNewButton() {
      // return true;
      if (!this.filingBook) {
        return false;
      }

      return (
        this.permissionCan("create") &&
        this.filingBook &&
        this.filingBook.status != "2"
      );
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.formModel = Object.assign({}, initialFormData());
        if (!value.editedId) {
          this.formModel.user_id = this.currentUser.id;
          let filingBookId = this.$route.params.filingBookId;
          if (filingBookId) {
            this.formModel.filing_book_id = +filingBookId;
          }

          // this.filingBook = this.getFilingBookByID(filingBookId);

          if (this.$refs.form) this.$refs.form.resetValidation();
          return null;
        }
        this.selectedLocale = i18nService.userLanguages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        // this.formModel = Object.assign({}, initialFormData());
        this.loader = true;

        ApiService.get(this.endPoint + value.editedId)
          .then(({ data }) => {
            data.status = 1 * data.status;
            this.formModel = Object.assign({}, data);
          })
          .catch((error) => {
            console.log("Error!: ", error);
          })
          .finally(() => {
            this.loader = false;
          });
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions(["fetchUser", "fetchFilingBook", "fetchEmployee"]),

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);
      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;

        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.showSnackBarInfo();
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(({ data }) => {
              this.showSnackBarInfo();
              this.$emit("saveModalForm", data);
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    onContext(ctx) {
      this.formModel.filing_date = ctx.selectedYMD;
    },

    onContext2(ctx) {
      this.formModel.postage_date = ctx.selectedYMD;
    },

    handleOpenUrl() {
      window.open(
        this.formModel.document_url,
        "_blank",
        "noopener, noreferrer"
      );
    },

    fetchModel() {
      this.fetchEmployee();
      return this.fetchFilingBook();
    },

    handleBack() {
      this.$router.push({ name: "filingBooks" });
    },
  },

  mounted() {
    this.fetchModel();
  },
};
</script>

<style scoped>
.grey--text {
  color: #464e5f !important;
  cursor: pointer;
}

.grey--text:hover {
  color: #e33354 !important;
  cursor: pointer;
}
</style>
